import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import { EChangeSubscriptionStage, TStore } from "models";
import styles from "./styles.module.scss";
import ugenieLogoWhite from "assets/images/ugenie-logo-white.png";
import { changeSubscriptionStageAction } from "store";
import { isItAWhiteLabel } from "utils";

const stateSelectorHandle = createSelector(
	(state: TStore) => state.community,
	(state: TStore) => state.subscription.changeSubscriptionStage,
	(state: TStore) => state.previewWhiteLabelCommunity,
	(community, changeSubscriptionStage, previewWhiteLabelCommunity) => ({
		community,
		changeSubscriptionStage,
		previewWhiteLabelCommunity,
	}),
);

interface IChangeSubscriptionHeader {}

const ChangeSubscriptionHeader: React.FunctionComponent<
	IChangeSubscriptionHeader
> = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const stateSelector = useCallback(stateSelectorHandle, []);
	const { community, changeSubscriptionStage, previewWhiteLabelCommunity } =
		useSelector(stateSelector);

	const communityLogo = useMemo((): {
		path: string;
		rounded: boolean;
	} => {
		if (!community?.data)
			return {
				path: previewWhiteLabelCommunity
					? previewWhiteLabelCommunity?.picture
					: ugenieLogoWhite,
				rounded: false,
			};

		if (community.data.picture) {
			return {
				path: community.data.picture,
				rounded: true,
			};
		} else {
			return {
				path: previewWhiteLabelCommunity
					? previewWhiteLabelCommunity?.picture
					: ugenieLogoWhite,
				rounded: false,
			};
		}
	}, [community, previewWhiteLabelCommunity]);

	const handleNavigation = useCallback(
		(path: string) => () => {
			if (!path) return;
			navigate(path);
		},
		[navigate],
	);

	const handleBackBtn = () => {
		if (changeSubscriptionStage === EChangeSubscriptionStage.memberPlans) {
			navigate(-1);
		} else {
			dispatch(
				changeSubscriptionStageAction({
					stage: EChangeSubscriptionStage.memberPlans,
				}),
			);
		}
	};

	return (
		<div className={styles.header}>
			<div className={styles.leftPositionedButton}>
				<Button
					className={classNames(styles.primaryColorTextBtn)}
					style={{ width: 100 }}
					onClick={handleBackBtn}
				>
					<FontAwesomeIcon
						className={styles.arrowLeftIcon}
						icon={faArrowLeft as unknown as IconProp}
					/>
					{"Back"}
				</Button>
			</div>

			<img
				className={classNames({
					[styles.roundLogo]: communityLogo.rounded,
					[styles.objectFitContainedLogo]: !communityLogo.rounded,
				})}
				src={communityLogo.path}
				alt="onboarding-logo"
			/>
			<Button
				className={classNames(styles.primaryColorTextBtn)}
				style={{ width: 100 }}
				onClick={() => {
					if (
						isItAWhiteLabel() &&
						process.env.REACT_APP_LOGOUT_URL &&
						process.env.REACT_APP_SSO
					) {
						window.location.assign(
							process.env.REACT_APP_LOGOUT_URL,
						);
					} else {
						handleNavigation("/auth/sign-out");
					}
				}}
			>
				{"Logout"}
			</Button>
		</div>
	);
};

export default ChangeSubscriptionHeader;
